import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
// import Imgpath from "./img_path";
// import Header from "./header";
import About_img from "./assets/img/about_img.png";
import About_img2 from"./assets/img/aboutimg.png";
import Ab_1 from "./assets/img/about-img/ab-1.png" 
import Cir1 from "./assets/img/port-circle-1.png";
import Cir2 from "./assets/img/port-circle-2.png";
import Cir3 from "./assets/img/port-circle-3.png";
import Alg from "./assets/img/alg.png";
import {Button,Container,Row,Col,Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import Gototop from "./gototop";


class about_page extends React.Component
{
    render()
    {
        return(
            <div>
                <section  className="bg-img">
                    <Container className="about_banner">
                            <Row className="banner_height_set align-items-center ">
                                <Col sm={12} md={6} lg={6} className="col_align">
                                    <div className="banner_para_align">
                                        <p className="about_head_para">ABOUT</p>
                                        <h2 className="about_h2">CHRONICLE OF TAGCS</h2>
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                    <Image src={About_img} alt="about_img" className="about_img_align"/>
                                </Col>
                            </Row>
                        </Container>
                </section>
                <section className="section-padding">
                    <Container>
                    <Row>
                    <Col sm={12} md={12} lg={6}  className="mx-auto d-none d-lg-block ">
                        <div className="">
                            <Image fluid src={Ab_1} alt="about"/>
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={6}>
                        <div className="title_align">
                             <p className="subtitle">Our Goal</p>
                            <h2 className="">Building software for world changers</h2>
                            <p>Our goal is to provide reliable customised web/mobile applications & Web Hosting service at economical cost that every individual can afford his own website/application/mobile app.</p>
                            <p>We are passionate about our work and would do everything to make software and web/mobile applications unique and simple for our clients.</p>
                            <p>TAGCS Web Service is a professional, affordable plus quality-based Web Hosting and Domain Name Registration service provider, payment gateway services, SMS service provider, ISP cache out services & Logistics integration. Our hosting solutions provide clients ample of liberty to focus and grow their business by commendably managing their critical data centre infrastructure and maintenance.</p>
                            <div className="count-number">
                                <Row>
                                    <Col xs={6} sm={6} md={6} lg={6}>
                                        <h2 className="mb-2 text-center">500+</h2>
                                        <p className="text-center">Projects Completed</p>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6}>
                                        <h2 className="mb-2 text-center">500+</h2>
                                        <p className=" text-center">Satisfied Clients</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} sm={6} md={6} lg={6}>
                                        <h2 className="mb-2 text-center">500+</h2>
                                        <p className=" text-center">Positive Feedbacks</p>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6}>
                                        <h2 className="mb-2 text-center">1,500+</h2>
                                        <p className=" text-center">Freebies Released</p>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={6}  className="mx-auto d-block d-lg-none">
                        <div className="">
                            <Image fluid src={Ab_1} alt="about"/>
                        </div>
                    </Col>
                    </Row>
                    </Container>
                </section>
                <section className="portfolio section section-padding-equal project-column-4 gradient">
                    <Container>
                        <Row>
                            <Col sm={12} md={12} lg={6}>
                                <div className="sec_heading_align title_align">
                                    <h2 className="sec_heading"> What do we do? </h2>
                                    <p class="sec_sub_heading subtitle">We understand your requirement and provide quality works. </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col sm={12} md={12} lg={6}>
                            <div className=" caro_content">
                            <Image fluid src={About_img2} alt="what-do-you-do" className=" d-block d-md-block d-lg-none"/>
                                <p className="">TAGCS is a diversified IT services company. We offer a comprehensive variety of IT services and solutions that can satisfy our clients in all ways.</p>
                                <p>TAGCS have been creating striking, affordable, dynamic and practical software, web & Mobile App solutions for organizations of all kinds. TAGCS creates technology-empowered IT solutions and services by combining industry leading processes and technologies that deliver business values to clients. We started our journey by developing customer software app and now we are into SaaS model (Software as a Service)- 
Single platform multiple business application with cost effective, Data Security and is accessible from anywhere in the world.
</p>
                                <p>We are known for our ability to work in true partnership with clients to bring development in their business using our high productivity and quality-based service.</p>
                                <p>We have completed many development projects and have covered most of the sectors namely Medical, Education, Hotel Management, Hospital Management, Human Resource Management, Banking, Retail, IT, and Finance.</p>
                                <p>In TAGCS, we develop Web, Mobile App and Software Solutions, and our work explains the quality of design and content which is a testimony to our commitment towards quality.</p>
                                <p>A vital constituent of our business is to offer our clients a top-level customer satisfaction, which has been the driving force behind our continuous growth and success.</p>
                                <p>We maintain an enviable customer-retention rate and repeat orders from clients.</p>
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={6} className="mx-auto">
                                <Image fluid src={About_img2} alt="what-do-you-do" className="d-none d-md-none d-lg-block"/>
                            </Col>
                        </Row>
                    </Container>
                    <ul className="list-unstyled shape-group-7">
                        <li className="shape shape-1">
                            <img className="img-fluid" src={Cir1} alt="Circle" />
                        </li>
                        <li className="shape shape-2">
                            <img className="img-fluid" src={Cir2} alt="Circle" />
                        </li>
                        <li className="shape shape-3">
                            <img className="img-fluid" src={Cir3} alt="Circle" />
                        </li>
                    </ul>
                </section>
            
                {/* <section className="section_align sec_bg_img about-padding">
                    <div className="overlay_about"></div>
                    <Container className="text-center">
                        <h2  className="joinus">We are passionate about our work and would <br/>do everything</h2>
                        <Link to="/career">
                            <Button variant="btn join_btn">Join Us</Button>
                        </Link>
                    </Container>
                </section>
           
                <section className="">
                    <div className="">
                        <Container>
                            <Row className="about_paddy">
                                <Col sm={12} md={6} lg={6}>
                                    <div class="gooey"></div>
                                    <Image fluid src={Alg} alt="alg" className=""/>
                                </Col>
                                <Col sm={12} md={6} lg={6} className="text-center">
                                    <p className="text-center alg_para_align">“Every problem is a gift—without problems we would not grow.”</p>
                                    <Button variant="btn join_btn">Know More</Button>
                                </Col>
                            </Row>
                        </Container>
                        <Gototop/>
                    </div>
                </section> */}
                <Gototop/>
            </div>
        )
    }
     
    
}
export default about_page;