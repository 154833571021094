import React from "react";
import {Link} from "react-router-dom";
import * as Rb from "react-bootstrap";
import * as Bi from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import Alpha from "./assets/img/alpha.png";
import Cloud from "./assets/img/cloud.png";
import Copy from "./assets/img/copy.png";
import Dev from "./assets/img/dev.png";
import SEO from "./assets/img/seo-pro.png";
import Mob from "./assets/img/mobile.png";
import HalfCir from "./assets/img/half-circle.png";
import Line1 from "./assets/img/line-4.png";
import MobApp from "./assets/img/mob-app.png";
import CusDev from "./assets/img/cus-dev.png";
import Ser_img from "./assets/img/ser-img.png";
import Device from "./assets/img/devices.png";
import Seo from "./assets/img/seo.png";
import Img from "./assets/img/admin.png";
import Content from "./assets/img/content.png";
import App_img from "./assets/img/home-mobile.png";
import Infotech from "./assets/img/infotech.png";
import Graph from "./assets/img/graph.png";
import "./home.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Gototop from "./gototop";




const test = {
    items: 1,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    rewind: true,
    autoplay: true,
    margin: 0,
    nav: true,

    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
    },
  };

class service extends React.Component
{
    render(){
        return(
            <div>
                <section  className="bg-img">
                    <Rb.Container className="about_banner">
                            <Rb.Row className="banner_height_set align-items-center ">
                                <Rb.Col sm={12} md={6} lg={6} className="col_align">
                                    <div className="banner_para_align">
                                        <p className="about_head_para"></p>
                                        <h2 className="about_h2">SERVICES</h2>
                                    </div>
                                </Rb.Col>
                                <Rb.Col sm={12} md={6} lg={6}>
                                    <Rb.Image src={Ser_img} alt="services_img" className="about_img_align"/>
                                </Rb.Col>
                            </Rb.Row>
                        </Rb.Container>
                </section>
                <section className="section section-padding-equal gradient">
                <div className="container">
                    <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12 mx-auto">
                                    <div className="product-grid page service-style-2">
                                        <div className="content">
                                            <div className="thumbnail floatLeft">
                                                <img className="img-fluid" src={Dev} alt="icon" />
                                            </div>
                                            <h5 className="title my-sm-2 py-sm-2">
                                                Customised Software Development 
                                            </h5>
                                            <div className="floatLeft">
                                            <p className="">Our initial development started with customised software app and now we are into SaaS model (Software as a Service) - Single platform multiple business application with cost effective, Data Security & accessible from anywhere. We deliver a flexible and consistent experience for users across platforms. We implement new approaches to web development including progressive web apps and also bring front-end, back-end and architecture ability together to deliver your business essentials with maximum delivery speed.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12 mx-auto">
                                    <div className="product-grid page service-style-2">
                                        <div className="content">
                                            <div className="thumbnail floatLeft">
                                                <img className="img-fluid" src={Mob} alt="icon" />
                                            </div>
                                            <h5 className="title my-sm-2 py-sm-2">
                                                Customised Mobile Apps
                                            </h5>
                                            <div className="floatLeft">
                                            <p className="">Grow your business with performance-optimized; salable, mobile apps developed using TAGCS expertise with mobile technologies. We deliver powerful native, web, and hybrid mobile applications for engaging gaming solutions, enterprise & business workflow optimizations, useful & precise utilities, captivating educational platforms, plus lifestyle & entertainment access. The success of a business is hidden under mobile apps nowadays. We work with you to design a perfect good app which makes your business good to go. The app will be exclusive, intuitive and effortless for any user who works with it.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12 mx-auto">
                                    <div className="product-grid page service-style-2">
                                        <div className="content">
                                            <div className="thumbnail floatLeft">
                                                <img className="img-fluid" src={Alpha} alt="icon" />
                                            </div>
                                            <h5 className="title my-sm-2 py-sm-2">
                                                Tactile&nbsp;graphics
                                            </h5>
                                            <div className="floatLeft">
                                            <p className="">Tactile graphics are a means of conveying non-textual information to people who are blind or visually impaired, and may include tactile representations of pictures, maps, graphs, diagrams, and other images. A person with a visual impairment can feel these raised lines and surfaces in order to obtain the same information that people who are sighted get through looking at pictures or other visual images.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12 mx-auto">
                                    <div className="product-grid page service-style-2">
                                        <div className="content">
                                            <div className="thumbnail floatLeft">
                                                <img className="img-fluid" src={Copy} alt="icon" />
                                            </div>
                                            <h5 className="title my-sm-2 py-sm-2">
                                                Copywriting
                                            </h5>
                                            <div className="floatLeft">
                                            <p className="">Worthy websites, require worthy writing. Content Writing for the web can be especially complicated commerce, but you needn’t worry about it. Our crack team of copywriters can create, write, edit and/or proof your copy in the voice that will boom your clients.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12 mx-auto">
                                    <div className="product-grid page service-style-2">
                                        <div className="content">
                                            <div className="thumbnail floatLeft">
                                                <img className="img-fluid" src={Cloud} alt="icon" />
                                            </div>
                                            <h5 className="title my-sm-2 py-sm-2">
                                                Information Architecture
                                            </h5>
                                            <div className="floatLeft">
                                            <p className="">We believe that Great design with well-organized contents will yield a successful website. Our team will get you the best design on your budget and provide high data security which is accessible from anywhere in the world along with the support of SEO.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12 mx-auto">
                                    <div className="product-grid page service-style-2">
                                        <div className="content">
                                            <div className="thumbnail floatLeft">
                                                <img className="img-fluid" src={SEO} alt="icon" />
                                            </div>
                                            <h5 className="title my-sm-2 py-sm-2">
                                                Search&nbsp;Optimization (SEO)
                                            </h5>
                                            <div className="floatLeft">
                                            <p className="">This is the backbone for searching your business in millions. We help you choose the words and have a best index for the search engine to clutch your targeted users easily.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* <Rb.Container className="p-3">
                        <div className=""></div>
                        <OwlCarousel className='owl-theme service_owl ' loop margin={10} nav {...test}>
                            <div class='slide-item item carousel_align caro_content  '>
                                <Rb.Row className="ser_height_set align-items-center">
                                    <Rb.Col sm={12} md={6} lg={6}>
                                        <h2 className="">customised Software <br/>Development</h2>
                                            <Rb.Image fluid src={Img} alt="devices" className="d-block d-sm-none"/>
                                        <p className="">Our initial development started with customer software app and now we are into SaaS model (Software as a Service) - Single platform multiple business application with cost effective, Data Security & accessible from anywhere. We deliver a flexible and consistent experience for users across platforms. We implement new approaches to web development including progressive web apps and also bring front-end, back-end and architecture ability together to deliver your business essentials with maximum delivery speed.</p>
                                        <Link to="/contact">
                                            <Rb.Button variant="btn alg_btn">Enquiry</Rb.Button>
                                        </Link>
                                    </Rb.Col>
                                    <Rb.Col sm={12} md={6} lg={6}>
                                        <Rb.Image fluid src={Dev} alt="devices" className="ser-float d-none d-sm-block"/>
                                    </Rb.Col>
                                </Rb.Row>
                            </div>
                            <div class='slide-item item'>
                                <div class='carousel_align caro_content '>
                                    <Rb.Row className="ser_height_set align-items-center">
                                        <Rb.Col sm={12} md={6} lg={6}>
                                            <h2 className="">customised Mobile Apps</h2>
                                                <Rb.Image fluid src={App_img} alt="devices" className="d-block d-sm-none"/>
                                            <p className="">Grow your business with performance-optimized; salable mobile apps developed using TAGCS's expertise with mobile technologies. We deliver powerful native, web, and hybrid mobile applications for engaging gaming solutions, enterprise & business workflow optimizations, useful & precise utilities, captivating educational platforms, plus lifestyle & entertainment access. The success of a business is hidden under mobile apps nowadays. We work with you to design a perfect good app which makes your business good to go. The app will be exclusive, intuitive and effortless for any user who works with it.</p>
                                            <Link to="/contact">
                                                <Rb.Button variant="btn alg_btn">Enquiry</Rb.Button>
                                            </Link>
                                        </Rb.Col>
                                        <Rb.Col sm={12} md={6} lg={6}>
                                            <Rb.Image fluid src={Mob} alt="devices" className=" ser-float d-none d-sm-block"/>
                                        </Rb.Col>
                                    </Rb.Row>
                                </div>
                            </div>
                            <div class='slide-item item'>
                                <div class=' carousel_align caro_content'>
                                    <Rb.Row className="ser_height_set align-items-center">
                                        <Rb.Col sm={12} md={6} lg={6}>
                                            <h2 className="">Tactile graphics</h2>
                                                <Rb.Image fluid src={Graph} alt="devices" className="d-block d-sm-none"/>
                                            <p className="">Tactile graphics are a means of conveying non-textual information to people who are blind or visually impaired, and may include tactile representations of pictures, maps, graphs, diagrams, and other images. A person with a visual impairment can feel these raised lines and surfaces in order to obtain the same information that people who are sighted get through looking at pictures or other visual images.</p>
                                            <Link to="/contact">
                                                <Rb.Button variant="btn alg_btn">Enquiry</Rb.Button>
                                            </Link>
                                        </Rb.Col>
                                        <Rb.Col sm={12} md={6} lg={6}>
                                            <Rb.Image fluid src={Alpha} alt="devices" className="ser-float d-none d-sm-block"/>
                                        </Rb.Col>
                                    </Rb.Row>
                                </div>
                            </div>
                            <div class='slide-item item'>
                                <div class=' carousel_align caro_content'>
                                    <Rb.Row className="ser_height_set align-items-center">
                                        <Rb.Col sm={12} md={6} lg={6}>
                                            <h2 className="">Copywriting</h2>
                                                <Rb.Image fluid src={Content} alt="devices" className="d-block d-sm-none"/>
                                            <p className="">Worthy websites, require worthy writing. Content Writing for the web can be especially complicated commerce, but you needn’t worry about it. Our crack team of copywriters can create, write, edit and/or proof your copy in the voice that will boom your clients.</p>
                                            <Link to="/contact">
                                                <Rb.Button variant="btn alg_btn">Enquiry</Rb.Button>
                                            </Link>
                                        </Rb.Col>
                                        <Rb.Col sm={12} md={6} lg={6}>
                                            <Rb.Image fluid src={Copy} alt="devices" className="ser-float d-none d-sm-block"/>
                                        </Rb.Col>
                                    </Rb.Row>
                                </div>
                            </div>
                            <div class='slide-item item'>
                                <div class=' carousel_align caro_content'>
                                    <Rb.Row className="ser_height_set align-items-center">
                                        <Rb.Col sm={12} md={6} lg={6} className="">
                                            <h2 className="">Information Architecture</h2>
                                                <Rb.Image fluid src={Infotech} alt="devices" className="d-block d-sm-none"/>
                                            <p className="">We believe that Great design with well-organized contents will yield a successful website. Our team will get you the best design on your budget and provide high data security which is accessible from anywhere in the world along with the support of SEO.</p>
                                            <Link to="/contact">
                                                <Rb.Button variant="btn alg_btn">Enquiry</Rb.Button>
                                            </Link>
                                        </Rb.Col>
                                        <Rb.Col sm={12} md={6} lg={6}>
                                            <Rb.Image fluid src={Cloud} alt="devices"className="ser-float d-none d-sm-block"/>
                                        </Rb.Col>
                                    </Rb.Row>
                                </div>
                            </div>
                            <div class='slide-item item'>
                                <div class='carousel_align caro_content '>
                                    <Rb.Row className="ser_height_set align-items-center">
                                        <Rb.Col sm={12} md={6} lg={6}>
                                            <h2 className="">Search Optimization (SEO)</h2>
                                                <Rb.Image fluid src={Seo} alt="devices" className="d-block d-sm-none"/>
                                            <p className="">This is the backbone for searching your business in millions. We help you choose the words and have a best index for the search engine to clutch your targeted users easily.</p>
                                            <Rb.Button variant="btn alg_btn">Enquiry</Rb.Button>
                                        </Rb.Col>
                                        <Rb.Col sm={12} md={6} lg={6}>
                                            <Rb.Image fluid src={SEO} alt="devices" className="ser-float d-none d-sm-block"/>
                                        </Rb.Col>
                                    </Rb.Row>
                                </div>
                            </div>
                        </OwlCarousel>
                    </Rb.Container> */}
                    <ul className="list-unstyled shape-group-6">
                        <li className="shape shape-1">
                            <img className="img-fluid" src={HalfCir} alt="Circle" />
                        </li>
                        <li className="shape shape-2">
                            <img className="img-fluid" src={Line1} alt="Circle" />
                        </li>
                    </ul>
                </section>
                <Gototop/>
            </div>
        )
    }
} 
export default service