import React from "react";
import {Link} from "react-router-dom";
import  { useState } from 'react';
import * as Rb from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Bi from "react-icons/bs";
import "./home.css";
import TCir1 from "./assets/img/top-cir1.png";
import Food from "./assets/img/s-food.png";
import Pawn from "./assets/img/s-pawn.png";
import Support from "./assets/img/s-support.png";
import Hospital from "./assets/img/s-medical.png";
import Hr from "./assets/img/s-hr.png";
import CusDev from "./assets/img/s-retail.png";
import Bub1 from "./assets/img/S-bubble1.png";
import Bub2 from "./assets/img/S-bubble2.png";
import Bub3 from "./assets/img/S-bubble3.png";
import Port_img from "./assets/img/port.png";
import Cl1 from "./assets/img/portfolio/Cl-1.png";
import Cl2 from "./assets/img/portfolio/Cl-2.png";
import Cl3 from "./assets/img/portfolio/Cl-3.png";
import Cl4 from "./assets/img/portfolio/Cl-4.png";
import Cl5 from "./assets/img/portfolio/Cl-5.png";
import Cl6 from "./assets/img/portfolio/Cl-6.png";
import Cl7 from "./assets/img/portfolio/Cl-7.png";
import Cl8 from "./assets/img/portfolio/Cl-8.png";
import Cl9 from "./assets/img/portfolio/Cl-9.png";
import Cir1 from "./assets/img/port-circle-1.png";
import Cir2 from "./assets/img/port-circle-2.png";
import Cir3 from "./assets/img/port-circle-3.png";
import Tab from "./assets/img/tab2.png";
import Portimg_1 from "./assets/img/port_img1.png";
import Portimg_2 from "./assets/img/port_img2.png";
import Portimg_3 from "./assets/img/port_img3.png";
import Portimg_4 from "./assets/img/port_img4.png";
import Portimg_5 from "./assets/img/port_img5.png";
import Portimg_6 from "./assets/img/port_img6.png";
import Portimg_7 from "./assets/img/port_img7.png";
import Portimg_8 from "./assets/img/port_img8.png";
import Portimg_9 from "./assets/img/port_img9.png";
import Portimg_10 from "./assets/img/port_img10.png";
import Portimg_11 from "./assets/img/port_img11.png";
import Portimg_12 from "./assets/img/port_img12.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Gototop from "./gototop";

class hotelmanagement extends React.Component
{
    render(){
       
        return(
            <div>
                <section  className="breadcrum-area breadcrumb-banner single-breadcrumb">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="section-heading heading-left">
                                    <h1 className="title h2">Hotel Management System</h1>
                                    <p>A quick view of industry specific problems solved with design by the awesome team at Keystroke.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="banner-thumbnail">
                                    <img className="img-fluid" src={Food} alt="icon" />
                                </div>
                            </div>
                        </div>
                    </div>  
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1">
                            <img className="img-fluid" src={Bub1} alt="icon" />
                        </li>
                        <li className="shape shape-2">
                            <img className="img-fluid" src={Bub2} alt="icon" />
                        </li>
                        <li className="shape shape-3">
                            <img className="img-fluid" src={Bub3} alt="icon" />
                        </li>
                    </ul>  
                </section>
                <section className="section-padding single-portfolio-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="section-heading heading-left mb-0">
                                    <h3 className="title">Hotel Management System</h3>
                                </div>
                                <p>Technology is making deep inroads into the hospitality industry, and hotels across the world are looking to use technology to make operation smooth and efficient.</p>
                                <p>The quest for performance and efficiency in the hospitality industry has given rise to the development of hotel management systems.</p>
                                <p>TAGCS has developed a hotel management software that incorporates best practices in all the automated departments so as to facilitate rapid decision making and better performance.</p>
                                <p>The software has been created by using latest technology and assimilation of industry experts’ opinion aimed to enhance usability of the designed product.</p>
                                <p>Our Hotel Management Software has a comprehensive range of integrated and advanced modules that cover each and every aspect of managing day to day hospitality business activities.</p>
                                <p>TAGCS Hotel Management software is innovatively designed and loaded with present day business needs features to seamlessly manage different types of hospitality.</p>
                                <p>We look forward to the continued endeavour to provide the best and top-quality service for our clients.</p>
                                <a className="axil-btn btn-fill-primary" href="https://tagit.store/restaurant-pricing.html" target="blank">Get it Now</a>
                            </div>
                            <div className="col-lg-6 offset-xl-1">
                                <div className="why-choose-us">
                                    <div className="section-heading heading-left">
                                    <table className="table striped">
                                                <thead className="thear_align">
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Particulars</th>
                                                        <th>S</th>
                                                        <th>G</th>
                                                        <th>D</th>
                                                        <th>P</th>
                                                        <th>T</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><span className="font800">1</span></td>
                                                        <td><span className="font800">Configuration</span></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Room</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Room Cancel</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Bed</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Laundry</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Product</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Channel Partner</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Festival</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Tax</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="font800">2</span></td>
                                                        <td><span className="font800">Registration</span></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Guest</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Employee</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Corporate</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="font800">3</span></td>
                                                        <td><span className="font800">Booking</span></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Reservation</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Check in</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Check out</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Cancel Views</td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Group Check in</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Group Check out</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Group Views</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Room Swapping</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="font800">4</span></td>
                                                        <td><span className="font800">Billing</span></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>KOT</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Restaurant Billing</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Bar Billing</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Bed</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Laundry</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Views</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Billing Payments</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Additional Charges</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="font800">5</span></td>
                                                        <td><span className="font800">Admin panel</span></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>User Creation</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Password change</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>User Rights</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Log Details</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Card Settlement</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Travel Agent Commission</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="font800">6</span></td>
                                                        <td><span className="font800">Accounts</span></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Day book</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Inward / Outward</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Cheque Configuration</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Consolidate Reports</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Cashier Reports</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Due Reports</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Tax Reports</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>TDS Reports</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Daily Reports</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Bar Reports</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Restaurant Reports</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Discount Reports</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Card Payment Reports</td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Night Audit Reports</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                        <td><Bi.BsCheck2Circle className="tick_icon"/></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Gototop/>
            </div>
        )
    }
}
export default hotelmanagement