import React from "react";
import {Link} from "react-router-dom";
import * as Rb from "react-bootstrap";
import * as Bi from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
class footer extends React.Component
{
    render(){
        return(
            <div>
                <section className="footer_sec">
                    <Rb.Container className="pt-4">
                        <Rb.Row>
                            <Rb.Col sm={12} md={6} lg={3}>
                                <div className="footer_align">
                                    <h6>CLIENT's REVIEW</h6>
                                    <ul className="list-unstyled">
                                        <li className=""><a target="blank" href="https://www.youtube.com/watch?v=IEoYA6d0M8k&list=PLldqupaGSAezzNtmHmsPflJlCqlPRtP98&index=12">Mr. Ga. Chockalingam - Reframe</a></li>
                                        <li className=""><a target="blank" href="https://www.youtube.com/watch?v=Sz6spn_AN88&list=PLldqupaGSAezzNtmHmsPflJlCqlPRtP98&index=14">Mr. Muthu - StudySmart</a></li>
                                        <li className=""><a target="blank" href="https://www.youtube.com/watch?v=49-BzYKh2ag&list=PLldqupaGSAezzNtmHmsPflJlCqlPRtP98&index=13">Mr.Senthil Kumar - COBOTA</a></li>
                                        <li className=""><a target="blank" href="https://www.youtube.com/watch?v=hxWRiMWulXQ&list=PLldqupaGSAezzNtmHmsPflJlCqlPRtP98&index=18">Dr.V.Nadanasabapathy - CREED</a></li>
                                        <li className=""><a target="blank" href="https://www.youtube.com/watch?v=ibb-jISUEDA&list=PLldqupaGSAezzNtmHmsPflJlCqlPRtP98">More...</a></li>
                                    </ul>
                                </div>
                            </Rb.Col>
                            <Rb.Col sm={12} md={6} lg={3}>
                                <div className="footer_align">
                                    <h6>TEAM's REVIEW</h6>
                                    <ul className="list-unstyled">
                                        <li className=""><a target="blank" href="https://www.youtube.com/watch?v=ZsFPfIV-yrg&list=PLldqupaGSAez4Iw6RtONolH3PnyhjPWSY">Mr. S. Rajkumar - Software Engineer</a></li>
                                        <li className=""><a target="blank" href="https://www.youtube.com/watch?v=rPT084vmvUg&list=PLldqupaGSAez4Iw6RtONolH3PnyhjPWSY&index=9">Mr. Ramesh - UI Developer</a></li>
                                        <li className=""><a target="blank" href="https://www.youtube.com/watch?v=0f_YwvoFqPw&list=PLldqupaGSAez4Iw6RtONolH3PnyhjPWSY&index=10">Mrs. Preethi Nehru - Server Administrator</a></li>
                                        <li className=""><a target="blank" href="https://www.youtube.com/watch?v=SB0o6cVGcoY&list=PLldqupaGSAez4Iw6RtONolH3PnyhjPWSY&index=12">Mr. Sathish Kumar - System Administrator</a></li>
                                        <li className=""><a target="blank" href="https://www.youtube.com/watch?v=ZsFPfIV-yrg&list=PLldqupaGSAez4Iw6RtONolH3PnyhjPWSY">More...</a></li>
                                    </ul>
                                </div>
                            </Rb.Col>
                            {/* <Rb.Col sm={12} mx={1}>
                                <h4> REGISTERED OFFICE</h4>
                                <ul className="">
                                    <li className="">30/24, 2nd cross, Gandhi Nagar, Chidambaram- 608001</li>
                                </ul>
                            </Rb.Col> */}
                            <Rb.Col sm={12} md={6} lg={3}>
                            <div className="footer_align">
                                <h6>CORPORATE OFFICE</h6>
                                <ul className="list-unstyled">
                                    <li className="address_align">Ganesh Enclave, No 40C,Murugan Kovil Street, 1st Floor,Vivekananda Nagar Main Road, Near Senthil Nagar Signal, Kolathur, Chennai- 600099, Tamilnadu, India</li>
                                </ul>
                                <h6> REGISTERED OFFICE</h6>
                                <ul className="list-unstyled">
                                    <li className="address_align">BALAMBIGAI TOWERS, 30/24, 2nd cross, Gandhi Nagar, Chidambaram - 608001, Cuddalore District, Tamilnadu, India</li>
                                </ul>
                            </div>
                            </Rb.Col>
                            <Rb.Col sm={12} md={6} lg={3}>
                            <div className="footer_align">
                                <h6>SUPPORT</h6>
                                <ul className="list-unstyled mb-0">
                                    <li className=""><Bi.BsPhone className="text-white me-2"/>+91 98431 66444</li>
                                    <li className=""><Bi.BsEnvelope className="text-white me-2"/>istorecare@tagcs.in</li>
                                </ul>
                                <h6 className="mt-2">SALES</h6>
                                <ul className="list-unstyled mb-0">
                                    <li className=""><Bi.BsPhone className="text-white me-2"/>+91 95859 40444</li>
                                    <li className=""><Bi.BsEnvelope className="text-white me-2"/>sales@tagcs.in</li>
                                </ul>
                                <h6 className="mt-2">EMAIL</h6>
                                <ul className="list-unstyled mb-0">
                                    <li className=""><Bi.BsEnvelope className="text-white me-2"/>info@tagcs.in</li>
                                </ul>
                                <hr className="footer_hr"/>
                                <ul className="list-unstyled d-inline-flex pt-0">
                                    
                                    <li className="foot_icon"><a target="blank" href="https://www.facebook.com/TAGTECHNOLOGYSERVICES/"><Bi.BsFacebook/></a></li>
                                    
                                                                        
                                    <li className="foot_icon"><a target="blank" href="https://twitter.com/TAGTES_groups"><Bi.BsTwitter/></a></li>
                                   
                                    
                                    <li className="foot_icon"><a target="blank" href="https://www.youtube.com/channel/UCslrKYViEkB8kWdP9HaxHaQ"><Bi.BsYoutube/></a></li>
                                  
                                   
                                    <li className="foot_icon"><a target="blank" href="https://www.linkedin.com/company/tagtes/"><Bi.BsLinkedin/></a></li>
                                 
                                </ul>
                            </div>
                            
                            </Rb.Col>
                        </Rb.Row>
                        <hr/>
                        <Rb.Row>
                            <Rb.Col sm={12} md={12} lg={6}>
                                <div className="copyright">
                                <p className="text-center">Copyrights © 2023 TAGCS Private Limited. All Rights Reserved.</p>
                                </div>
                            </Rb.Col>
                            <Rb.Col sm={12} md={12} lg={6}>
                                <div className="bytagtes">
                                <p className="text-center">Design & Developed By <span><a target="blank" href="/" >TAGCS.</a></span></p>
                                </div>
                            </Rb.Col>
                        </Rb.Row>
                    </Rb.Container>
                </section>
            </div>
        )
    }
}
export default footer;